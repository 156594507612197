<template>
  
  <h2>Multimultiplier</h2>
  
  <div class="options" v-cloak>
    
    <select v-if="!about" name="" id="" @change="addProcess" v-model="next">
      <option :value="null">Add operation...</option>
      <option v-for="(option, o) in options" :value="option" v-text="option.name" :key="'o-'+o"></option>
    </select>
    <h4 v-else>About</h4>
    
    <a v-if="!about" href="#" @click.prevent="about = true">About...</a>
    <a v-else href="#" @click.prevent="about = false">Close &times;</a>
  </div>
  
  <div v-if="about" v-cloak>
    <p>For the rare occasions when you need to multiply two numbers by the same amount.</p>
    <p>Or any other operation for that matter...</p>
    <p>Or any combination of operations.</p>
    <p>In a drag and droppable order.</p>
    <h3>Round</h3>
    <p>Enter the number of decimal places you'd like to see.</p>
    <h3>Closest</h3>
    <p>Enter a number for it to round to. i.e. if the number is 24, and you put 5 into the closest box, it will round to 25.</p>
    <h3>Pow</h3>
    <p>To the power of...</p>
  </div>
  

  <draggable v-if="!about" v-model="process" class="process" handle=".handle" v-cloak item-key="s-">
    <template #item="{element}">
      <div class="process--step">
        <div class="handle"></div>
        <div class="process--label">
          <label for="" v-text="element.name"></label>
          <span class="process--delete" @click="removeStep(index)">Delete</span>
        </div>
        <input type="number" v-model="element.value" />
      </div>
    </template>
  </draggable>
  
  <div class="instances" v-if="!about" v-cloak>
    <div class="instance">
      <code>Input</code>
      <code>Output</code>
    </div>
    <div v-for="(instance, index) in instances" class="instance" :key="'i-'+index">
      <input type="number" v-model="instance.input">
      <input type="number" readonly="readonly" :value="getOutput(instance.input)" :ref="'output'+index">
      <button @click="copyOutput(index)" class="instance--copy">Copy</button>
      <button @click="removeInstance(index)" class="instance--delete">Delete</button>
    </div>
    
    <button @click="instances.push({input: 1})">Add row</button>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  components: {
    draggable
  },
  data(){
    return {
      about: false,
      next: null,
      instances: [{
        input: 1,
      },{
        input: 4,
      },],
      // Current operations
      process: [{
        name: "multiply",
        value: 5,
      },{
        name: "add",
        value: 1,
      }],
      // Options
      options: [{
        name: 'add',
        value: 1,
      },{
        name: 'subtract',
        value: 1,
      },{
        name: 'divide',
        value: 3,
      },{
        name: 'multiply',
        value: 2,
      },{
        name: 'round',
        value: 0,
      },{
        name: 'closest',
        value: 7,
      },{
        name: 'pow',
        value: 2,
      }]
    }
  },
  methods: {
    copyOutput(index){
      let output = this.$refs['output'+index][0];
      output.select();
      document.execCommand("copy");
    },
    removeStep(index){
      this.process.splice(index, 1);
    },
    removeInstance(index){
      this.instances.splice(index, 1);
    },
    addProcess(){
      if(this.next){
        this.process.push({
          name: this.next.name,
          value: this.next.value
        });
      }
      this.next = null;
    },
    getOutput(input){
      input = parseFloat(input);
      this.process.forEach(process => {
        let value = parseFloat(process.value);
        if(isNaN(value)){
          return
        }
        if(process.name == 'add'){
          input += value;
        }else if(process.name == 'subtract'){
          input -= value;
        }else if(process.name == 'multiply'){
          input *= value;
        }else if(process.name == 'divide'){
          input /= value;
        }else if(process.name == 'round'){
          let amount = Math.pow(10, value);
          input = Math.round(input * amount) / amount;
        }else if(process.name == 'closest'){
          input = Math.round(input / value) * value;
        }else if(process.name == 'pow'){
          input = Math.pow(input, value);
        }
      });
      return input;
    }
  },
}
</script>

<style lang="scss">
$orange: #FF5722;
$background: #282829;
$red: #ff2222;
$grey: #ccc;

@mixin shadow(){
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);;
} 

@mixin radius(){
  border-radius: 5px;
} 

*{
  box-sizing: border-box;
  font-family: 'Lexend Mega', sans-serif;
  transition: all 0.2s;
  opacity: 1;
}

body, input, button, select{
  font-family: 'Lexend Mega', sans-serif;
}

code, pre{
  font-family: 'Space Mono', monospace;
  letter-spacing: 0.1em;
}

body{
  font-size: 11px;
  padding: 1em;
  @media(min-width: 600px){
    padding: 2em;
    font-size: 15px;
  }
  background: $background;
  color: #fff;
}

.process{
  display: block;
  flex-wrap: wrap;
  &--step{
    border: 1px solid #eaeaea;
    @include radius;
    padding: 10px;
    
    padding-left: 20px;
    @media(min-width: 600px){
      padding-left: 30px + 10px;
    }
    background: $background;
    display: inline-flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-right: 10px;
    @media(min-width: 600px){
      margin-top: 20px;
      margin-right: 20px;
    }
    & > *{
      margin-left: 5px;
      margin-right: 5px;
    }
    input{
      width: 80px;
      margin: 0;
    }
    .handle{
      display: block;
      width: 15px;
      @media(min-width: 600px){
        width: 30px;
      }
      height: 100%;
      left: 0;
      top: 0;
      margin: 0;
      background: rgba(#fff, 0.3);
      position: absolute;
      cursor: all-scroll;
    }
  }
  &--delete{
    color: $red;
    font-size: 9px;
    @media(min-width: 600px){
      font-size: 13px;
    }
    margin-top: 5px;
    cursor: pointer;
  }
  &--label{
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    @media(min-width: 600px){
      padding-right: 20px;
    }
  }
}

.instances{
  padding: 20px 0;
  display: grid;
  grid-gap: 20px;
}

.instance{
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  @media (min-width: 500px){
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) 80px 80px;
  }
  width: 100%;
  max-width: 100%;
  grid-gap: 10px;
  input{
    margin: 0;
    &[readonly="readonly"]{
      color: $grey;
    }
  }
  button, .button{
    height: auto;
    padding: 4px;
    font-size: 1em;
    @media(min-width:600px){
      padding: 10px;
      font-size: 14px;
    }
  }
  &--copy{
    background: #448AFF;
  }
  &--delete{
    background: $red;
  }
}

input, select{
  appearance: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
  border: 1px solid $grey;
  background: $background;
  padding: 6px 8px;
  @media(min-width: 600px){
    padding: 15px;
  }
  margin-bottom: 1em;
  color: #fff;
  @include radius;
  @include shadow;
  &:focus, &:active{
    outline: none;
    background: #fff;
    color: #000;
  }
}

button, .button{
  @include radius;
  background: $orange;
  background: linear-gradient(0deg, $red 0%, $orange 100%);
  border: none;
  padding: 1em;
  color: #000;
  @media(min-width: 600px){
    font-size: 20px;
  }
  &:focus, &:active{
    outline: none;
    background: #fff;
  }
}

.options {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  a{
    padding: 10px;
    position: relative;
    left: -10px;
    color: #448AFF;
    text-decoration: none;
  }
  select{
    margin: 0;
  }
}

#app{
  width: 100%;
  @media(min-width: 600px){
    width: 90%;
  }
  max-width: 900px;
  margin: 0 auto;
}

[v-cloak]{
  opacity: 0;
}

p + h3{
  margin-top: 2em;
}
</style>
